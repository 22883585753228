import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { Link } from "react-router-dom";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/register";

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter(
    (page, i) => page.name === "Register"
  )[0];

  let registerTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let registerTranslationZhTW = { meta_desc: page.meta.desc_tc };
  i18n.addResourceBundle("zh_CN", "translation", registerTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", registerTranslationZhTW);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    if (errMsg !== "") {
      Array.from(document.getElementsByClassName("danger")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("danger");
          dangerEle.classList.add("resolve");
        }
      );
      setTimeout(function () {
        setErrMsg("");
      }, 1000);
    } else {
      setErrMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      await axios.post(REGISTER_URL, JSON.stringify({ user, pwd }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setSuccess(true);
      setUser("");
      setPwd("");
      setMatchPwd("");
    } catch (err) {
      Array.from(document.getElementsByClassName("UserLayout")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("resolve");
          dangerEle.classList.add("danger");
        }
      );
      if (!err?.response) {
        setErrMsg(t("No Server Response"));
      } else if (err.response?.status === 409) {
        setErrMsg(t("Username Taken"));
      } else {
        setErrMsg(t("Registration Failed"));
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <MetaDecorator title={t("Register")} description={t("meta_desc")} />
      {success ? (
        <section className="component register">
          <h1>{t("Success")}!</h1>
          <p>
            <Link to="/user/login">{t("Sign In")}</Link>
          </p>
        </section>
      ) : (
        <section className="component register">
          <h1>{t("Register")}</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">
              {t("Username")}
              <FontAwesomeIcon
                icon={faCheck}
                className={validName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validName || !user ? "hide" : "invalid"}
              />
            </label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              required
              aria-invalid={validName ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            />
            <div className="tooltip">
              <p
                id="uidnote"
                className={
                  userFocus && user && !validName ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Must be")}
                <br />
                1) {t("4 to 24 characters.")}
                <br />
                2) {t("Must begin with a letter.")}
                <br />
                3) {t("Letters, numbers, underscores, hyphens allowed.")}
              </p>
            </div>

            <label htmlFor="password">
              {t("Password")}
              <FontAwesomeIcon
                icon={faCheck}
                className={validPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validPwd || !pwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              aria-invalid={validPwd ? "false" : "true"}
              aria-describedby="pwdnote"
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
              autoComplete="new-password"
            />
            <div className="tooltip">
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Must be")}
                <br />
                {t("8 to 24 characters.")}
                <br />
                {t(
                  "Must include uppercase and lowercase letters, a number and a special character."
                )}
                <br />
                {t("Allowed special characters")}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </p>
            </div>

            <label htmlFor="confirm_pwd">
              {t("Confirm Password")}
              <FontAwesomeIcon
                icon={faCheck}
                className={validMatch && matchPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMatch || !matchPwd ? "hide" : "invalid"}
              />
            </label>
            <input
              type="password"
              id="confirm_pwd"
              onChange={(e) => setMatchPwd(e.target.value)}
              value={matchPwd}
              required
              aria-invalid={validMatch ? "false" : "true"}
              aria-describedby="confirmnote"
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
            <div className="tooltip">
              <p
                id="confirmnote"
                className={
                  matchFocus && !validMatch ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {t("Must be")}
                <br />
                {t("Must match the first password input field.")}
              </p>
            </div>

            <div className="signup">
              <button
                disabled={!validName || !validPwd || !validMatch ? true : false}
              >
                {t("Sign Up")}
              </button>
            </div>
          </form>
          <div>
            <h5>{t("Already registered")}</h5>
            <br />
            <Link to="/user/login">
              <h6>{t("Sign In")}</h6>
            </Link>
          </div>
          <p
            ref={errRef}
            className={`errMsg${errMsg ? " danger" : ""}`}
            aria-live="assertive"
          >
            {errMsg}
          </p>
        </section>
      )}
    </>
  );
};

export default Register;
