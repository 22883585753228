import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaDecorator = ({ title, description = "", image = "" }) => {
  const { t, i18n } = useTranslation();

  const metaTitle =
    (title ? title + " - " : "") + t(process.env.REACT_APP_WEBSITE_NAME);
  const metaDesc = description !== "" ? description : "";
  const metaImg =
    image !== "" ? image : require("../assets/images/meta/og-image.png");
  const metaLocale = i18n.resolvedLanguage;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={metaDesc} />
        <meta name="title" key="title" content={metaTitle} />
        <meta property="og:title" key="og:title" content={metaTitle} />
        <meta property="og:locale" key="og:locale" content={metaLocale} />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={metaDesc}
        />
        <meta property="og:image" key="og:image" content={metaImg} />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaDecorator;
