import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import java from "react-syntax-highlighter/dist/esm/languages/prism/java";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPaste } from "@fortawesome/free-solid-svg-icons";

SyntaxHighlighter.registerLanguage("java", java);

const CodeBlock = ({ children, language }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [copied]);

  if (typeof children[0] === "object") {
    const mergeObjStr = (c) => {
      if (typeof c === "undefined") return "";
      if (typeof c[0] !== "object") return c[0];
      c = [
        c
          .map((_c, i) => {
            return mergeObjStr(_c?.props?.children);
          })
          .join("\n"),
      ];
      return c;
    };
    children = mergeObjStr(children);
  }

  children[0] = children[0]?.replace(new RegExp("\\n$", "g"), () => "");

  return (
    <div className="codeblock">
      <CopyToClipboard text={children} onCopy={() => setCopied(true)}>
        <button
          className="icon copy-icon"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon icon={copied ? faPaste : faCopy} />
        </button>
      </CopyToClipboard>
      <SyntaxHighlighter
        language={language}
        style={materialDark}
        showLineNumbers
      >
        {children}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
