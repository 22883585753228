import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

import axios from "../api/axios";
const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, resetUser, userAttribs] = useInput("user", "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [check, toggleCheck] = useToggle("persist", false);
  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter((page, i) => page.name === "Login")[0];

  let loginTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let loginTranslationZhTW = { meta_desc: page.meta.desc_tc };
  i18n.addResourceBundle("zh_CN", "translation", loginTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", loginTranslationZhTW);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    if (errMsg !== "") {
      Array.from(document.getElementsByClassName("danger")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("danger");
          dangerEle.classList.add("resolve");
        }
      );
      setTimeout(function () {
        setErrMsg("");
      }, 1000);
    } else {
      setErrMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;

      const userid_res = await axios.post(
        "/users/get/id",
        JSON.stringify({ username: user }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
      const userId = userid_res?.data?.id;

      setAuth({ userId, user, accessToken });
      resetUser();
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      Array.from(document.getElementsByClassName("UserLayout")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("resolve");
          dangerEle.classList.add("danger");
        }
      );
      if (!err?.response) {
        setErrMsg(t("No Server Response"));
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <section className="component login">
      <MetaDecorator title={t("Login")} description={t("meta_desc")} />
      <h1>{t("Sign In")}</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">{t("Username")}</label>
        <input
          type="text"
          id="username"
          ref={userRef}
          autoComplete="off"
          {...userAttribs}
          required
        />
        <label htmlFor="password">{t("Password")}</label>
        <input
          type="password"
          id="password"
          onChange={(e) => setPwd(e.target.value)}
          value={pwd}
          required
        />
        <div className="persistCheck">
          <input
            type="checkbox"
            id="persist"
            onChange={toggleCheck}
            checked={check}
          />
          <label htmlFor="persist">{t("Trust This Device")}</label>
        </div>
        <div className="signin">
          <button>{t("Sign In")}</button>
        </div>
      </form>
      <div>
        <h5>{t("Need an Account")}?</h5>
        <br />
        <Link to="/user/register">
          <h6>{t("Sign Up")}</h6>
        </Link>
      </div>
      <p
        ref={errRef}
        className={`errMsg${errMsg ? " danger" : ""}`}
        aria-live="assertive"
      >
        {errMsg}
      </p>
    </section>
  );
};

export default Login;
