import websiteData from "../assets/json/website.json";
import programmingData from "../assets/json/programming.json";
import projectsData from "../assets/json/projects.json";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

const Home = () => {
  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter((page, i) => page.name === "Home")[0];

  const programmingLangData = programmingData.filter((lang, i) => {
    const isLang = ["library", "runtime", "framework"].includes(lang.type);
    return isLang;
  });

  const callSkillPt = (lang) => {
    let unitScore =
      (["year"].includes(lang.exp_unit) ? 12 : 1) *
      (["year", "month"].includes(lang.exp_unit) ? 30.4368499 : 1) *
      (["year", "month", "day"].includes(lang.exp_unit) ? 24 : 1) *
      (["year", "month", "day", "hour"].includes(lang.exp_unit) ? 3600 : 1);
    return lang.exp * unitScore;
  };

  const calSkillPtPercent = (lang) => {
    return Math.round(
      (callSkillPt(lang) / Math.max(...programmingLangData.map(callSkillPt))) *
        100
    );
  };

  let homeTranslationZhCN = {
    meta_desc: page.meta.desc_sc,
  };
  let homeTranslationZhTW = {
    meta_desc: page.meta.desc_tc,
  };
  projectsData.projects.forEach((proj) => {
    homeTranslationZhCN = {
      ...homeTranslationZhCN,
      [proj.name]: proj.name_sc,
    };
    homeTranslationZhTW = {
      ...homeTranslationZhTW,
      [proj.name]: proj.name_tc,
    };
  });
  i18n.addResourceBundle("zh_CN", "translation", homeTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", homeTranslationZhTW);

  return (
    <div className="Home">
      <MetaDecorator title={t("Home")} description={t("meta_desc")} />
      <h1 className="heading">{t("Home")}</h1>
      <div className="homeSkills">
        <h2>{t("MY SKILLS")}: </h2>
        <div className="skills">
          {programmingLangData.map((lang, i) => {
            return (
              <div className={`${lang.name}Prog ProgWidth`} key={`lang${i}`}>
                <div className="p">
                  <p>{lang.name}</p>
                  <p>{calSkillPtPercent(lang)}%</p>
                </div>
                <div className="pp">
                  <div className="skillDiv">
                    <span
                      className={`skillBar ${lang.name}Bar`}
                      style={{ width: `${calSkillPtPercent(lang)}%` }}
                    ></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="homeParticipated">
        <h2>{t("PARTICIPATED")}: </h2>
        <div className="participated">
          {projectsData.projects.map((proj, i) => {
            return (
              <div key={`proj${i}`}>
                <p>{t(proj.name)}</p>
                <a href={proj.url} target="_blank" rel="noreferrer">
                  {proj.url}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
