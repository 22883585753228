import PersistLogin from "./PersistLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import * as cfa from "../icons";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

library.add(fas, fab, cfa);

const Game = ({ gameData }) => {
  const persistLoginData = PersistLogin(false);
  const isLoggedIn =
    !persistLoginData.isLoading && persistLoginData.auth?.accessToken;

  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter((page, i) => page.name === "Games")[0];

  let gameTranslationZhCN = {
    meta_desc: page.meta.desc_sc,
    [gameData.name]: gameData.name_sc,
  };
  let gameTranslationZhTW = {
    meta_desc: page.meta.desc_tc,
    [gameData.name]: gameData.name_tc,
  };
  i18n.addResourceBundle("zh_CN", "translation", gameTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", gameTranslationZhTW);

  return (
    <div className="Game">
      <MetaDecorator title={t("Games")} description={t("meta_desc")} />
      <h1 className="heading">{t("Games")}</h1>
      <div className="gameInfo">
        <img
          src={require(`../assets/images/games/${gameData.uri}.png`)}
          style={{ objectFit: "cover", width: "50vw" }}
          alt={gameData.uri}
        />
        <h2>{t(gameData.name)}</h2>
      </div>
      <div className="gameFriends">
        <h2>- {t("Be Friends")}</h2>
        <div className="gameAccounts">
          {gameData.accounts.length < 1 ? (
            <div className="gameAccount">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        {t("No Available Account")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            gameData.accounts
              .filter(
                (game) => isLoggedIn || (!isLoggedIn && !game.sexual_content)
              )
              .map((account, j) => {
                return (
                  <div className="gameAccount" key={`game_account${j}`}>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <FontAwesomeIcon icon={["fas", "gamepad"]} />
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <span className="gameAccountNameTitle">
                                {t("Game ID")}:
                              </span>
                              <br />
                              <span className="gameAccountName">
                                {account.data.account_name}
                              </span>
                            </td>
                          </tr>
                          {account.data.client_profile_name ? (
                            <tr>
                              <td>
                                <FontAwesomeIcon
                                  icon={
                                    account.data.client_company_icon ?? [
                                      "fas",
                                      "gamepad",
                                    ]
                                  }
                                />
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <span className="gameAccountNameTitle">
                                  {t(
                                    account.data.client_company_name ??
                                      account.data.client_name
                                  )}
                                  :
                                </span>
                                <br />
                                {account.data.client_profile_link ? (
                                  <a
                                    href={account.data.client_profile_link}
                                    className="gameAccountName"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {account.data.client_profile_name}
                                  </a>
                                ) : (
                                  <span className="gameAccountName">
                                    {account.data.client_profile_name}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {!account.data.tags.every(
                            (t) => t.display === false
                          ) ? (
                            <tr>
                              <td colSpan={2}>
                                <div className="gameAccountTags">
                                  {account.data.tags.map((tag, l) =>
                                    tag.display ? (
                                      <div
                                        style={{ display: "inline" }}
                                        key={`tag${l}`}
                                      >
                                        {Array.isArray(tag.data) ? (
                                          tag.data.map((tagd, m) => (
                                            <code
                                              className={`tag`}
                                              key={`tagd${m}`}
                                            >
                                              {t(tagd.name ?? tagd)}
                                            </code>
                                          ))
                                        ) : (
                                          <code className={`tag ${tag.class}`}>
                                            {tag.name ?? tag}
                                          </code>
                                        )}
                                      </div>
                                    ) : (
                                      <div key={`tag${l}`}></div>
                                    )
                                  )}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default Game;
