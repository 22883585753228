import { Link } from "react-router-dom";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Nav = ({ navData }) => {
  const { t, i18n } = useTranslation();

  const isExternal = (url) => {
    var match = url.match(
      /^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/
    );
    if (
      typeof match[1] === "string" &&
      match[1].length > 0 &&
      match[1].toLowerCase() !== window.location.protocol
    )
      return true;
    if (
      typeof match[2] === "string" &&
      match[2].length > 0 &&
      match[2].replace(
        new RegExp(
          ":(" +
            { "http:": 80, "https:": 443 }[window.location.protocol] +
            ")?$"
        ),
        ""
      ) !== window.location.host
    )
      return true;
    return false;
  };

  let navTranslationZhCN = {};
  let navTranslationZhTW = {};
  navData.forEach((navItem) => {
    navTranslationZhCN = {
      ...navTranslationZhCN,
      [navItem.name]: navItem.name_sc,
    };
    navTranslationZhTW = {
      ...navTranslationZhTW,
      [navItem.name]: navItem.name_tc,
    };
  });
  i18n.addResourceBundle("zh_CN", "translation", navTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", navTranslationZhTW);

  return (
    <nav>
      <ol>
        <ul>
          {navData.map((navItem, i) => (
            <li key={`navItem${i}`}>
              {!isExternal(navItem.uri) ? (
                <Link to={navItem.uri} target={navItem.target}>
                  {t(navItem.name)} <FontAwesomeIcon icon={faCaretRight} />
                </Link>
              ) : (
                <a href={navItem.uri} target={navItem.target}>
                  {t(navItem.name)} <FontAwesomeIcon icon={faCaretRight} />
                </a>
              )}
            </li>
          ))}
        </ul>
      </ol>
    </nav>
  );
};

export default Nav;
