import { useNavigate, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";

const Dashboard = () => {
    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/user');
    }

    return (
        <>
            <section className="component dashboard">
                <h1>Dashboard</h1>
                <br />
                <p>You are logged in!</p>
                <br />
                <Link to="/user/editor">Go to the Editor page</Link>
                <br />
                <Link to="/user/admin">Go to the Admin page</Link>
                <br />
                <Link to="/user/lounge">Go to the Lounge</Link>
                <br />
                <Link to="/user/linkpage">Go to the link page</Link>
                <div className="flexGrow signout">
                    <button onClick={signOut}>Sign Out</button>
                </div>
            </section>
        </>
    )
}

export default Dashboard