import { useState } from "react";
import { Button, InputGroup } from "reactstrap";
import { ThemeContext, themes } from "../context/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const [darkMode, setDarkMode] = useState(true);
  const { i18n } = useTranslation();
  const lngs = props.lngs;
  return (
    <footer>
      <div style={{ float: "left" }}>
        <InputGroup>
          <ThemeContext.Consumer>
            {({ changeTheme }) => (
              <Button
                color="link"
                onClick={() => {
                  setDarkMode(!darkMode);
                  changeTheme(darkMode ? themes.light : themes.dark);
                }}
                style={{ background: "none", border: "none" }}
              >
                <span className="toogleDarkThemeBtnIcon">
                  {darkMode ? (
                    <FontAwesomeIcon icon={faMoon} style={{ color: "white" }} />
                  ) : (
                    <FontAwesomeIcon icon={faSun} style={{ color: "white" }} />
                  )}
                </span>
              </Button>
            )}
          </ThemeContext.Consumer>
        </InputGroup>
      </div>
      <div className="locale">
        {Object.keys(lngs).map((lng, i) => {
          return (
            <button
              id={lng}
              className={i18n.resolvedLanguage === lng ? "selected" : ""}
              onClick={() => i18n.changeLanguage(lng)}
              key={lng}
            >
              <img
                src={require(`../assets/images/${lng}.png`)}
                alt={`localeImg${i}`}
              />
            </button>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
