import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const KeyVisual = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="KeyVisual">
        <div className="KeyVisualText">
          <h1 className="KeyVisualHeading">{t("I am Martin Lock")}</h1>
          <p>{t("And I'm a Web Developer")}</p>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default KeyVisual;
