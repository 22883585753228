import Register from "./components/Register";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import Editor from "./components/Editor";
import Admin from "./components/Admin";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import Lounge from "./components/Lounge";
import LinkPage from "./components/LinkPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import Games from "./components/Games";
import Game from "./components/Game";
import KeyVisual from "./components/KeyVisual";
import UserLayout from "./components/UserLayout";
import Programming from "./components/Programming";
import TutorialCategory from "./components/TutorialCategory";
import Tutorial from "./components/Tutorial";
import tutsData from "./assets/json/programming.json";
import { getRoles, getGames } from "./helpers/index";
import Home from "./components/Home";

const ROLES = getRoles();

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<KeyVisual />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="contact" element={<KeyVisual />}>
          <Route index element={<Contact />} />
        </Route>
        <Route path="programming" element={<KeyVisual />}>
          <Route index element={<Programming tutsData={tutsData} />} />
          <Route path="tutorial">
            <Route index element={<TutorialCategory tutsData={tutsData} />} />
            {tutsData
              .filter((tutData, i) => tutData.tutorials.length > 0)
              .map((tutData, i) => (
                <Route path={tutData.uri} key={`tutData` + i}>
                  {tutData.tutorials.map((tut, j) => (
                    <Route
                      path={`${tut.chapter}`}
                      key={`tut` + j}
                      element={
                        <Tutorial
                          lang={tutData.name}
                          tut={tut}
                          min={Math.min(
                            ...tutData.tutorials.map((tut) => tut.chapter)
                          )}
                          max={Math.max(
                            ...tutData.tutorials.map((tut) => tut.chapter)
                          )}
                        />
                      }
                    />
                  ))}
                </Route>
              ))}
          </Route>
        </Route>
        <Route path="games" element={<KeyVisual />}>
          <Route index element={<Games />} />
          {getGames().map((game, i) => {
            let gameRoute = (
              <Route path={game.uri} key={`gameRoute${i}`}>
                <Route index element={<Game gameData={game} />} />
              </Route>
            );

            return game.sexual_content === true ? (
              <Route element={<PersistLogin />} key={`gameRoute${i}`}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]}
                    />
                  }
                >
                  {gameRoute}
                </Route>
              </Route>
            ) : (
              gameRoute
            );
          })}
        </Route>
        <Route path="user" element={<UserLayout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="linkpage" element={<LinkPage />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route index element={<Dashboard />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
              <Route path="editor" element={<Editor />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
              <Route path="admin" element={<Admin />} />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />
              }
            >
              <Route path="lounge" element={<Lounge />} />
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
