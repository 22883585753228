import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import contactData from "../assets/json/contact.json";
import websiteData from "../assets/json/website.json";
import PersistLogin from "./PersistLogin";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

library.add(fas, fab);

const MESSAGE_URL = "/message/get";
const SEND_MESSAGE_URL = "/message/act/send";
// const REPLY_MESSAGE_URL = "/message/act/reply";

const Contact = () => {
  const axiosPrivate = useAxiosPrivate();

  const msgTextarea = useRef();
  const errRef = useRef();

  const [leaveMsg, setLeaveMsg] = useState("");
  const [msgs, setMsgs] = useState([]);

  const [errMsg, setErrMsg] = useState("");

  const persistLoginData = PersistLogin(false);
  const isLoggedIn =
    !persistLoginData.isLoading && persistLoginData.auth?.accessToken;

  const effectRan = useRef(false); // fix react 18 strict mode request the api twice

  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter(
    (page, i) => page.name === "Contact"
  )[0];

  let contactTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let contactTranslationZhTW = { meta_desc: page.meta.desc_tc };
  contactData.contact.forEach((contact) => {
    contactTranslationZhCN = {
      ...contactTranslationZhCN,
      [contact.name]: contact.name_sc,
    };
    contactTranslationZhTW = {
      ...contactTranslationZhTW,
      [contact.name]: contact.name_tc,
    };
  });
  i18n.addResourceBundle("zh_CN", "translation", contactTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", contactTranslationZhTW);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      const getMsgs = async () => {
        try {
          const response = await axios.get(MESSAGE_URL, {
            signal: controller.signal,
          });

          isMounted && setMsgs(response.data);
        } catch (err) {
          console.error(err);
        }
      };

      getMsgs();
    }

    return () => {
      isMounted = false;
      controller.abort();
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (errMsg !== "") {
      Array.from(document.getElementsByClassName("danger")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("danger");
          dangerEle.classList.add("resolve");
        }
      );
      setTimeout(function () {
        setErrMsg("");
      }, 1000);
    } else {
      setErrMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgTextarea]);

  const handleMsgChange = (e) => {
    setLeaveMsg(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axiosPrivate.post(
        SEND_MESSAGE_URL,
        JSON.stringify({ leaveMsg, authorId: persistLoginData.auth?.userId })
      );
      setLeaveMsg("");
    } catch (err) {
      Array.from(document.getElementsByClassName("UserLayout")).forEach(
        (dangerEle) => {
          dangerEle.classList.remove("resolve");
          dangerEle.classList.add("danger");
        }
      );
      if (!err?.response) {
        setErrMsg(t("No Server Response"));
      } else {
        setErrMsg(t("Leave Message Failed"));
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="Contact">
      <MetaDecorator title={t("Contact")} description={t("meta_desc")} />
      <h1 className="heading">{t("Contact")}</h1>
      {contactData.contact
        .map((contact, i) => {
          return contact.type;
        })
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((contactType, i) => (
          <div key={`contactType${i}`}>
            <h2>
              - {t(contactType.charAt(0).toUpperCase() + contactType.slice(1))}
            </h2>
            <div className={`contactDetails ${contactType}`}>
              {contactData.contact
                .filter((v, i, a) => v.type === contactType)
                .map((contact, j) => (
                  <p
                    className={`contactDetailIcons ${contact.id}`}
                    key={`contact${j}`}
                  >
                    <FontAwesomeIcon icon={contact.icon} />
                    {t(contact.name)}:{" "}
                    {contact.uri ? (
                      <a href={contact.uri} target={contact.uri_target}>
                        {contact.value}
                      </a>
                    ) : (
                      contact.value
                    )}
                  </p>
                ))}
            </div>
          </div>
        ))}
      <hr className="hr-text" data-content={t("OR")}></hr>
      <form onSubmit={handleSubmit}>
        <div className="contactDetails leaveAMsg">
          <h2>{t("Leave me a message here")}</h2>
          {persistLoginData.isLoading ? (
            <>
              <div className="leaveAMsgTextareaWrapper">
                <div className="leaveAMsgUnlocker loading">
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <textarea
                  className="loading"
                  ref={msgTextarea}
                  name="leaveMsg"
                  id="leaveMsg"
                  maxLength="400"
                  onChange={handleMsgChange}
                  disabled={!isLoggedIn}
                ></textarea>
              </div>
              <span className="laveAMsgCounter">{t("Loading")}</span>
              <div className="leaveAMsgSend">
                <p>{t("Loading")}</p>
                <button type="submit" disabled>
                  {t("Send")}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="leaveAMsgTextareaWrapper">
                <div
                  className="leaveAMsgUnlocker"
                  style={!isLoggedIn ? {} : { display: "none" }}
                >
                  <p>
                    <Link to="/user/register">{t("Sign Up")}</Link>
                    {t("_to leave me a message")}
                  </p>
                  <p>
                    {t("Already have an account")}{" "}
                    <Link to="/user/login">{t("Sign In")}</Link>{" "}
                    {t("_Login_now")}
                  </p>
                </div>
                <textarea
                  ref={msgTextarea}
                  name="leaveMsg"
                  id="leaveMsg"
                  maxLength="400"
                  onChange={handleMsgChange}
                  disabled={!isLoggedIn}
                ></textarea>
              </div>
              <span className="laveAMsgCounter">
                {leaveMsg.length <= 400 ? 400 - leaveMsg.length : 0}
                {t("_letters remaining")}
              </span>
              <div className="leaveAMsgSend">
                <p>
                  {t("Logged in as")}{" "}
                  {isLoggedIn ? `${persistLoginData.auth?.user}` : t("Guest")}
                </p>
                <button type="submit" disabled={!isLoggedIn}>
                  {t("Send")}
                </button>
              </div>
            </>
          )}
        </div>
      </form>
      <div className="contactMsgs">
        {msgs.length ? (
          <>
            {msgs.map((msg, i) => (
              <div className="contactMsg" key={`contactMsg${i}`}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="profile">
                          <img
                            src={require(`../assets/images/profile/default.png`)}
                            className="profilePic"
                            alt={`profilePic${i}`}
                          />
                          <p style={{ textAlign: "center" }}>{msg.author}</p>
                        </div>
                      </td>
                      <td style={{ margin: "1rem", width: "100%" }}>
                        <div className="contactMsgCmt">{msg.msg}</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="socialWrapper">
                          <Link to="">
                            <FontAwesomeIcon icon={["fa", "comment"]} />{" "}
                            {t("Reply")}
                          </Link>
                          <Link to="">
                            <FontAwesomeIcon icon={["fa", "heart"]} />{" "}
                            {t("Like")}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </>
        ) : (
          <div>{t("No messages found.")}</div>
        )}
      </div>
      <p
        ref={errRef}
        className={`errMsg${errMsg ? " danger" : ""}`}
        aria-live="assertive"
      >
        {errMsg}
      </p>
    </div>
  );
};

export default Contact;
