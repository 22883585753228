import { Link } from "react-router-dom";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MetaDecorator from "./MetaDecorator";

const TutorialWrapper = ({ tutsData }) => {
  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter(
    (page, i) => page.name === "Programming"
  )[0];
  const location = useLocation();
  const pathname = location.pathname;

  let programmingTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let programmingTranslationZhTW = { meta_desc: page.meta.desc_tc };
  i18n.addResourceBundle("zh_CN", "translation", programmingTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", programmingTranslationZhTW);

  return (
    <div className="TutorialCategory">
      <MetaDecorator title={t("Programming")} description={t("meta_desc")} />
      <h1 className="heading">{t("Tutorial")}</h1>
      <div className="tutorialDetails">
        {tutsData
          .filter((tutData, i) => tutData.tutorials.length > 0)
          .map((tutData, i) => {
            return (
              <h2 key={`TutorialCategory${i}`}>
                <Link
                  to={`${pathname}/${tutData.uri}/${Math.min(
                    ...tutData.tutorials.map((tut) => tut.chapter)
                  )}`}
                >
                  {tutData.name}
                </Link>
              </h2>
            );
          })}
      </div>
    </div>
  );
};

export default TutorialWrapper;
