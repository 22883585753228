import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";
import MD from "./MD";

const Tutorial = ({ lang, tut, min, max }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter(
    (page, i) => page.name === "Programming"
  )[0];

  let tutTranslationEn = {
    meta_desc: page.meta.desc,
    topic: tut.topic,
    content: tut.content,
    question: tut.question,
  };
  let tutTranslationZhCN = {
    meta_desc: page.meta.desc_sc,
    topic: tut.topic_sc,
    content: tut.content_sc,
    question: tut.question_sc,
  };
  let tutTranslationZhTW = {
    meta_desc: page.meta.desc_tc,
    topic: tut.topic_tc,
    content: tut.content_tc,
    question: tut.question_tc,
  };
  i18n.addResourceBundle("en", "translation", tutTranslationEn);
  i18n.addResourceBundle("zh_CN", "translation", tutTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", tutTranslationZhTW);
  const location = useLocation();
  const pathname = location.pathname.split("/").slice(0, -1).join("/");
  const pageState = useRef({ curr: tut.chapter });

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      let ch = parseInt(window.location.href.split("/").pop());
      if (ch !== pageState.curr) {
        pageState.curr = ch;
        return;
      }
      let page = 0;
      switch (e.key) {
        case "ArrowLeft":
          page = ch - 1 >= min ? ch - 1 : max;
          break;
        case "ArrowRight":
          page = ch + 1 <= max ? ch + 1 : min;
          break;
        default:
          return;
      }
      navigate(`${pathname}/${page}`);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Tutorial">
      <MetaDecorator title={t("Programming")} description={t("meta_desc")} />
      <h1 className="heading">
        <Link
          to={`${pathname}/${tut.chapter - 1 >= min ? tut.chapter - 1 : max}`}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Link>
        {` ${t("Tutorial")} `}
        <Link
          to={`${pathname}/${tut.chapter + 1 <= max ? tut.chapter + 1 : min}`}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </h1>
      <h2>{`${t(lang)} (${t("Chapter")}${tut.chapter}) - ${t("topic")}`}</h2>
      <div className="tutorialContent">
        {t("content").match(new RegExp("^.+.md$", "g")) ? (
          <MD file_name={t("content")} />
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: t("content")
                .replace(
                  new RegExp(
                    "(?:```)(?:(.*?)\\n)((.|\\n)*?)(?:\\n)(?:```)",
                    "g"
                  ),
                  (match, lang, content) => {
                    return `<code>${content

                      .replace(
                        new RegExp('"(.*?)"', "g"),
                        (match, content) =>
                          `<span class="string">${match}</span>`
                      )
                      .replace(
                        new RegExp(
                          "(function|void)(?:\\s)([^\\s]+)(?:\\s)?(\\()",
                          "g"
                        ),
                        (match, keyword, funcname, openbracket) =>
                          `${keyword} <span class="funcname">${funcname}</span> ${openbracket}`
                      )
                      .replace(
                        new RegExp(
                          "^int|boolean|double|float|char|if|else|for|do|switch|while|true|false|null|public|static|void|private|final$",
                          "gm"
                        ),
                        (match, content) =>
                          `<span class="keyword">${match}</span>`
                      )
                      .replace(
                        new RegExp("[+-]?(\\d*\\.)?\\d+", "g"),
                        (match, content) => `<span class="int">${match}</span>`
                      )
                      .replace(
                        new RegExp("(?:/*)((?:.|\\n)*?)(?:\\*/)", "g"),
                        (match, content) =>
                          `<span class="comment">${match}</span>`
                      )
                      .replace(
                        new RegExp("(\\/\\/.*)", "g"),
                        (match, content) =>
                          `<span class="comment">${match}</span>`
                      )
                      .replace(
                        new RegExp("(case)(?:\\s)?(.*?)(:)", "g"),
                        (match, keyword, content) => `${keyword} ${content}:`
                      )
                      .replace(
                        new RegExp("(class)(?:\\s)([^\\s]+)(?:\\s)?({)", "g"),
                        (match, keyword, classname, openbracket) =>
                          `${keyword} <span class="classname">${classname}</span> ${openbracket}`
                      )}</code>`;
                  }
                )
                .replace(
                  new RegExp("`(.+?)`", "g"),
                  (match, content) => `<code class="qoute">${content}</code>`
                ),
            }}
          />
        )}
      </div>
      <div className="tutorialQuestion">
        <h2>{t("Question")}</h2>
        {t("question").match(new RegExp("^.+.md$", "g")) ? (
          <MD file_name={t("question")} />
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: t("question")
                .replace(
                  new RegExp(
                    "(?:```)(?:(.*?)\\n)((.|\\n)*?)(?:\\n)(?:```)",
                    "g"
                  ),
                  (match, lang, content) => {
                    return `<code>${content}</code>`;
                  }
                )
                .replace(
                  new RegExp("`(.+?)`", "g"),
                  (match, content) => `<code class="qoute">${content}</code>`
                ),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Tutorial;
