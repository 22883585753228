import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const cookie_res = await axios.post("/cookies/valid", JSON.stringify({}), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (cookie_res.status === 204) {
      setAuth();
      return;
    }
    const response = await axios.post("/refresh", JSON.stringify({}), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    const userid_res = await axios.post("/users/get/id", JSON.stringify({}), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${response.data.accessToken}`,
      },
      withCredentials: true,
    });
    const username_res = await axios.post(
      "/users/get/username",
      JSON.stringify({}),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response.data.accessToken}`,
        },
        withCredentials: true,
      }
    );
    setAuth((prev) => {
      // console.log(JSON.stringify(prev));
      // console.log(response.data.accessToken);
      return {
        ...prev,
        userId: userid_res.data.id,
        user: username_res.data.username,
        // roles: response.data.roles,
        accessToken: response.data.accessToken,
      };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
