import { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import CodeBlock from "./CodeBlock";
import React from "react";

const MD = ({ file_name }) => {
  const [post, setPost] = useState("");

  useEffect(() => {
    import(`../assets/md/${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) =>
            setPost(
              res.replace(
                new RegExp("(\\/\\*)([^()]*)(\\*\\/)", "g"),
                (match) => {
                  return match.replace(
                    new RegExp("\\*", "g"),
                    (match, a, b) => {
                      return "\\" + match;
                    }
                  );
                }
              )
            )
          );
      })
      .catch((err) => console.log(err));
  });

  let i = 0;

  return (
    <Markdown
      style={
        new RegExp("\\n(?:[`]{3}|<CodeBlock(?:.*)>)", "g").test(post)
          ? { display: "flex", flexDirection: "column", gap: "1rem" }
          : {}
      }
      options={{
        createElement(type, props, children) {
          if (typeof type === "string") {
            switch (type) {
              case "p":
                if (
                  typeof children === "object" &&
                  Object.keys(children).length > 0
                ) {
                  let hasCB =
                    children
                      .map((v, i) => {
                        return v.type?.name ?? v.type;
                      })
                      .filter((v) => v === "CodeBlock").length > 0;
                  if (hasCB) {
                    type = "div";
                    props = {
                      style: { whiteSpace: "pre-line" },
                      key: ++i,
                    };
                  }
                }
                break;
              default:
                break;
            }
          }
          return React.createElement(type, props, children);
        },
        overrides: {
          CodeBlock: {
            component: CodeBlock,
          },
          code: {
            props: {
              className: "qoute",
            },
          },
          a: {
            props: {
              target: "_blank",
            },
          },
        },
      }}
    >
      {post}
    </Markdown>
  );
};

export default MD;
