const Header = () => {
  return (
    <header>
      <div className="hamburger">
        <input type="checkbox" id="hamburgerMenuToogle" />
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
