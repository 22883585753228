import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Missing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="Missing">
      <h1>{t("An error as occured.")}</h1>
      <h1>
        {" "}
        <span className="ascii">(╯°□°）╯︵ ┻━┻</span>
      </h1>
      <button onClick={() => navigate(-1)}>{t("Go back")}</button>
    </div>
  );
};

export default Missing;
