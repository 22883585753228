import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import websiteData from "../assets/json/website.json";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MetaDecorator from "./MetaDecorator";

const Programming = ({ tutsData }) => {
  const { t, i18n } = useTranslation();
  const page = websiteData.pages.filter(
    (page, i) => page.name === "Programming"
  )[0];
  const location = useLocation();
  const pathname = location.pathname;

  let programmingTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let programmingTranslationZhTW = { meta_desc: page.meta.desc_tc };
  i18n.addResourceBundle("zh_CN", "translation", programmingTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", programmingTranslationZhTW);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (!document.getElementById("slider-container")) return;
      switch (e.key) {
        case "ArrowLeft":
          document.getElementById("slider-container").scrollLeft -= 270;
          break;
        case "ArrowRight":
          document.getElementById("slider-container").scrollLeft += 270;
          break;
        default:
          return;
      }
    });
  }, []);

  return (
    <div className="Programming">
      <MetaDecorator title={t("Programming")} description={t("meta_desc")} />
      <h1 className="heading">
        {
          <span
            onClick={() => {
              document.getElementById("slider-container").scrollLeft -= 270;
            }}
            className="arrows"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        }
        {` ${t("Programming")} `}
        {
          <span
            onClick={() => {
              document.getElementById("slider-container").scrollLeft += 270;
            }}
            className="arrows"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        }
      </h1>
      <div className="tutorialDetails slider" id="slider-container">
        {[
          "tutorial",
          ...tutsData
            .map((tutData, i) => {
              return tutData.type;
            })
            .filter((v, i, a) => a.indexOf(v) === i),
        ].map((tutType, i) => {
          return (
            <div className="slide" key={`Programming${i}`}>
              <Link to={`${pathname}/${tutType}`}>
                <img
                  src={require(`../assets/images/${tutType}.png`)}
                  alt={`tutType${i}`}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Programming;
