import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const effectRan = useRef(false); // fix react 18 strict mode request the api twice

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      const getUsers = async () => {
        try {
          const response = await axiosPrivate.get("/users", {
            signal: controller.signal,
          });
          const userNames = response.data.map((user) => user.username);
          isMounted && setUsers(userNames);
        } catch (err) {
          console.error(err);
          navigate("/user/login", { state: { from: location }, replace: true });
        }
      };

      getUsers();
    }

    return () => {
      isMounted = false;
      controller.abort();
      effectRan.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article>
      <h2>Users List</h2>
      {users?.length ? (
        <ul>
          {users.map((user, i) => (
            <li key={i}>{user}</li>
          ))}
        </ul>
      ) : (
        <p>No users to display</p>
      )}
    </article>
  );
};

export default Users;
