import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";
import websiteData from "../assets/json/website.json";

const lngs = {
  en: { nativeName: "Eng" },
  zh_CN: { nativeName: "简" },
  zh_TW: { nativeName: "繁" },
};

const handleCloseMenu = () => {
  Array.from(document.getElementsByTagName("nav")).forEach((nav) => {
    nav.classList.remove("open");
    nav.classList.add("close");
    document.getElementById("hamburgerMenuToogle").checked = false;
  });
};

const handleOpenCloseMenu = (e = null) => {
  // let deviceWidth =
  //   window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  // let isMobile = deviceWidth <= 1366;
  // if (!isMobile) return false;
  let viewpointWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  let isMobileWidth = viewpointWidth <= 1366;
  Array.from(document.getElementsByTagName("nav")).forEach((nav) => {
    if (e?.type === "resize") nav.classList.add("resizing");
    if (isMobileWidth) {
      let opened = nav.classList.contains("open");
      nav.style.height = "100%";
      if (e?.type !== "resize") {
        if (opened) {
          handleCloseMenu();
        } else {
          nav.classList.remove("close");
          nav.classList.add("open");
          document.getElementById("hamburgerMenuToogle").checked = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    } else {
      nav.style.height = "70px";
    }
  });
};

const Layout = () => {
  useEffect(() => {
    window.addEventListener("resize", handleOpenCloseMenu);

    var afterResized;
    window.onresize = function () {
      clearTimeout(afterResized);
      afterResized = setTimeout(() => {
        const nav = document.getElementsByTagName("nav")[0];
        if (nav.classList.contains("close")) nav.classList.remove("close");
        nav.classList.remove("resizing");
      }, 100);
    };

    const handleDocumentMouseUp = (e) => {
      const nav = document.getElementsByTagName("nav")[0];
      if (e.target.id === "hamburgerMenuToogle") {
        handleOpenCloseMenu();
      } else {
        let a = e.target;
        let els = [];
        let isClickable = false;
        while (a) {
          if (
            !isClickable &&
            ((typeof a.getAttribute !== "undefined" &&
              (a.getAttribute("onclick") != null ||
                a.getAttribute("href") != null)) ||
              a.tagName === "BUTTON")
          )
            isClickable = true;
          els.unshift(a);
          a = a.parentNode;
        }
        if (
          (isClickable && !els.includes(nav)) ||
          (!isClickable && els.includes(nav))
        )
          return;
        let opened = nav.classList.contains("open");
        if (opened) handleCloseMenu();
      }
    };
    document.addEventListener("click", handleDocumentMouseUp);

    return () => {
      window.removeEventListener("resize", handleOpenCloseMenu);
      document.removeEventListener("click", handleDocumentMouseUp);
    };
  }, []);

  const navData = websiteData.nav.map((page, i) => {
    if (!Number.isInteger(page.page)) return page;
    return { ...page, ...websiteData.pages[page.page] };
  });

  return (
    <>
      <Header />
      <Nav navData={navData} />
      <main className="App">
        <Outlet />
      </main>
      <Footer lngs={lngs} />
    </>
  );
};

export default Layout;
