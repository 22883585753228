import { Outlet } from "react-router-dom";

const UserLayout = () => {
    return (
        <>
            <div className="UserLayout">
                <Outlet />    
            </div>
        </>
    );
}
 
export default UserLayout;
