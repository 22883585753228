import { Link } from "react-router-dom"
import Users from './Users';

const Admin = () => {
    return (
        <>
            <section className="component admin">
                <h1>Admins Page</h1>
                <br />
                <Users />
                <br />
                <div className="flexGrow">
                    <Link to="/user">Home</Link>
                </div>
            </section>
        </>
    )
}

export default Admin