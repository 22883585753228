import gamesData from "../assets/json/games.json";
import i18n from "../i18n";

/**
 * General
 */

export function getRoles() {
  return {
    User: 2001,
    Editor: 1984,
    Admin: 5150,
  };
}

export function getRolePower(role) {
  return getRoles()[role];
}

export function getRoleName(power) {
  let roles = getRoles();
  return Object.keys(roles).find((key) => roles[key] === power);
}

export function getSlug(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

/**
 * Games
 */

export function getGames() {
  return gamesData.games.map((game, i) => {
    game.uri = getSlug(game.name);
    if (game.accounts.length < 1) return game;
    game.accounts = game.accounts.map((account, j) => {
      let account_name =
        account.name ?? gamesData.client_profile[account?.client_profile]?.name;
      let client_name = account.hasOwnProperty("client")
        ? gamesData.clients[account.client].name
        : account.hasOwnProperty("client_profile")
        ? gamesData.clients[
            gamesData.client_profile[account.client_profile].client
          ].name
        : "";
      let client_company_icon = account.hasOwnProperty("client_profile")
        ? gamesData.companies[
            gamesData.clients[
              gamesData.client_profile[account.client_profile].client
            ].company
          ]?.icon
        : "";
      let client_company_name = account.hasOwnProperty("client_profile")
        ? gamesData.companies[
            gamesData.clients[
              gamesData.client_profile[account.client_profile].client
            ].company
          ]?.name
        : account.hasOwnProperty("client")
        ? gamesData.companies[gamesData.clients[account.client].company].name
        : "";
      let client_profile_link = account.hasOwnProperty("client_profile")
        ? gamesData.client_profile[account.client_profile].link
        : "";
      let client_profile_name = account.hasOwnProperty("client_profile")
        ? gamesData.client_profile[account?.client_profile]?.name
        : "";
      let devices = account.devices ?? [];
      let server_name = account.server ?? "";
      let dlc = account.dlc ?? [];
      let target_country = account.target_country ?? "";
      let private_server = account.private ?? false;
      let sexual_content = game.sexual_content ?? false;
      let closed_server = account.closed ?? game.closed ?? false;
      let banned = account.banned ?? false;
      let tags = [
        {
          name: client_company_name,
          data: client_company_name,
          class: "",
          display: client_company_name ? true : false,
        },
        {
          name: i18n.t("Devices"),
          data: devices,
          class: "",
          display: account.devices ? true : false,
        },
        {
          name: server_name,
          data: server_name,
          class: "",
          display: server_name !== "",
        },
        {
          name: "DLC",
          data: dlc,
          class: "",
          display: account.dlc ? true : false,
        },
        {
          name: i18n.t(`${target_country} Server`),
          data: target_country,
          class: "",
          display: target_country ?? false,
        },
        {
          name: i18n.t("Private"),
          data: private_server,
          class: "psrv",
          display: private_server,
        },
        {
          name: i18n.t("Sexual"),
          data: sexual_content,
          class: "sexual",
          display: sexual_content,
        },
        {
          name: i18n.t("Closed"),
          data: closed_server,
          class: "closed",
          display: closed_server,
        },
        {
          name: i18n.t("Banned"),
          data: banned,
          class: "banned",
          display: banned,
        },
      ];
      return {
        ...account,
        data: {
          account_name,
          client_name,
          client_company_icon,
          client_company_name,
          client_profile_link,
          client_profile_name,
          devices,
          server_name,
          dlc,
          target_country,
          private_server,
          sexual_content,
          closed_server,
          banned,
          tags,
        },
      };
    });
    return game;
  });
}
