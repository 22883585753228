import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import * as cfa from "../icons";
import { getGames } from "../helpers/index";
import websiteData from "../assets/json/website.json";
import PersistLogin from "./PersistLogin";
import { useTranslation } from "react-i18next";
import MetaDecorator from "./MetaDecorator";

library.add(fas, fab, cfa);

const Games = () => {
  const persistLoginData = PersistLogin(false);
  const isLoggedIn =
    !persistLoginData.isLoading && persistLoginData.auth?.accessToken;

  const { t, i18n } = useTranslation();
  const gamesData = getGames();
  const page = websiteData.pages.filter((page, i) => page.name === "Games")[0];
  const filterSearchBarRef = useRef();
  const [query, setQuery] = useState("");

  let gamesTranslationZhCN = { meta_desc: page.meta.desc_sc };
  let gamesTranslationZhTW = { meta_desc: page.meta.desc_tc };
  gamesData.forEach((game) => {
    gamesTranslationZhCN = {
      ...gamesTranslationZhCN,
      [game.name]: game.name_sc,
    };
    gamesTranslationZhTW = {
      ...gamesTranslationZhTW,
      [game.name]: game.name_tc,
    };
  });
  i18n.addResourceBundle("zh_CN", "translation", gamesTranslationZhCN);
  i18n.addResourceBundle("zh_TW", "translation", gamesTranslationZhTW);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (!document.getElementById("slider-container")) return;
      switch (e.key) {
        case "ArrowLeft":
          document.getElementById("slider-container").scrollLeft -= 270;
          break;
        case "ArrowRight":
          document.getElementById("slider-container").scrollLeft += 270;
          break;
        default:
          return;
      }
    });
  }, []);

  useEffect(() => {
    filterSearchBarRef.current.focus();
  }, []);

  useEffect(() => {
    let searchList = {};
    gamesData.forEach((game) => {
      searchList[game.uri] = [
        { id: game.uri, name: game.name, lang: "en", locale: "en" },
        { id: game.uri, name: game.name_tc, lang: "tc", locale: "zh_TW" },
        { id: game.uri, name: game.name_sc, lang: "sc", locale: "zh_CN" },
      ];
      for (const i in searchList) {
        searchList[game.uri].order = i;
        searchList[game.uri].id = game.uri;
      }
    });
    for (const i in searchList) {
      let searchObjs = searchList[i];
      for (const searchItem in searchObjs) {
        if (typeof searchObjs[searchItem] !== "object") continue;
        searchObjs[searchItem].scoreList = [];
        [...new Set(query.toLowerCase().split(""))].forEach((char) => {
          if (char.trim() === "") return;
          if (searchObjs[searchItem].name.toLocaleLowerCase().includes(char))
            searchObjs[searchItem].scoreList.push({
              msg: `Character (${char}): Same letter found.`,
              score: 1,
            });
          if (
            (
              query.match(
                new RegExp(char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g")
              ) || []
            ).length ===
            (
              searchObjs[searchItem].name
                .toLocaleLowerCase()
                .match(
                  new RegExp(char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g")
                ) || []
            ).length
          )
            searchObjs[searchItem].scoreList.push({
              msg: `Character (${char}): Same number of same letter character(s) usage found.`,
              score:
                (
                  searchObjs[searchItem].name
                    .toLocaleLowerCase()
                    .match(
                      new RegExp(
                        char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                        "g"
                      )
                    ) || []
                ).length * 1,
            });
        });
        [...new Set(query.split(""))].forEach((char) => {
          if (char.trim() === "") return;
          if (searchObjs[searchItem].name.includes(char))
            searchObjs[searchItem].scoreList.push({
              msg: `Character (${char}): Exact character match found.`,
              score: 1,
            });
          if (i18n.language === searchObjs[searchItem].locale) {
            searchObjs[searchItem].scoreList.push({
              msg: `Character (${char}): Same locale found.`,
              score: 1,
            });
          }
          if (
            (
              query.match(
                new RegExp(char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g")
              ) || []
            ).length ===
            (
              searchObjs[searchItem].name.match(
                new RegExp(char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g")
              ) || []
            ).length
          ) {
            searchObjs[searchItem].scoreList.push({
              msg: `Character (${char}): Same number of exact matched character(s) usage found.`,
              score:
                (
                  searchObjs[searchItem].name.match(
                    new RegExp(char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g")
                  ) || []
                ).length * 2,
            });
          }
        });
        query
          .toLowerCase()
          .split(" ")
          .forEach((word) => {
            if (word.trim() === "") return;
            if (
              searchObjs[searchItem].name.toLowerCase().indexOf(word) !== -1 &&
              query.length > 0
            )
              searchObjs[searchItem].scoreList.push({
                msg: `Word (${word}): Same letter word match found.`,
                score: word.length * 100,
              });
          });
        query.split(" ").forEach((word) => {
          if (word.trim() === "") return;
          if (
            searchObjs[searchItem].name.indexOf(word) !== -1 &&
            query.length > 0
          )
            searchObjs[searchItem].scoreList.push({
              msg: `Word (${word}): Exact word match found.`,
              score: word.length * 200,
            });
        });
        searchObjs[searchItem].score = searchObjs[searchItem].scoreList
          .map((si) => si.score)
          .reduce((partialSum, a) => partialSum + a, 0);
      }
      searchObjs.score = searchObjs
        .map((si) => si.score)
        .reduce((partialSum, a) => partialSum + a, 0);
    }
    let sortable = [];
    for (var searchItem in searchList) {
      sortable.push(searchList[searchItem]);
    }
    sortable.sort((a, b) => {
      return b.score - a.score;
    });
    searchList = sortable;
    if (searchList === []) return;
    var wrapper = document.getElementById("slider-container");
    var items = wrapper.children;
    for (var idx in items) {
      let item = items[idx];
      if (typeof item !== "object") continue;
      if (!item.id) return;
      item.style.display =
        searchList.map((si) => si.score).reduce((a, b) => a + b, 0) === 0
          ? ""
          : "none";
      let avg =
        searchList.map((si) => si.score).reduce((a, b) => a + b, 0) /
          searchList.map((si) => si.score).length || 0;
      if (searchList.filter((si) => si.id === item.id)[0].score > avg) {
        item.style.display = "";
      }
    }
    var eles = document.createDocumentFragment();
    for (var jdx in searchList) {
      if (!document.getElementById(searchList[jdx].id)) continue;
      eles.appendChild(
        document.getElementById(searchList[jdx].id).cloneNode(true)
      );
    }
    wrapper.innerHTML = null;
    wrapper.appendChild(eles);
    // eslint-disable-next-line
  }, [query]);

  gamesData.sort((a, b) => {
    return ("" + a.name).localeCompare(b.name);
  });

  return (
    <div className="Games">
      <MetaDecorator title={t("Games")} description={t("meta_desc")} />
      <h1 className="heading">
        {
          <span
            onClick={() => {
              document.getElementById("slider-container").scrollLeft -= 270;
            }}
            className="arrows"
          >
            <FontAwesomeIcon icon={["fas", "chevron-left"]} />
          </span>
        }
        {` ${t("Games")} `}
        {
          <span
            onClick={() => {
              document.getElementById("slider-container").scrollLeft += 270;
            }}
            className="arrows"
          >
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />
          </span>
        }
      </h1>
      <input
        type="search"
        className="filterSearchBar"
        placeholder={t("What are you looking for?")}
        ref={filterSearchBarRef}
        autoComplete="off"
        onChange={(e) => setQuery(e.target.value)}
      ></input>
      <div className="gamesDetails slider" id="slider-container">
        {gamesData.map((game, i) => {
          return !isLoggedIn && game.sexual_content ? (
            false
          ) : (
            <div className="slide" id={game.uri} key={`game${i}`}>
              <Link
                to={`/games/${game.name
                  .toLowerCase()
                  .trim()
                  .replace(/[^\w\s-]/g, "")
                  .replace(/[\s_-]+/g, "-")
                  .replace(/^-+|-+$/g, "")}`}
              >
                <img
                  src={require(`../assets/images/games/${game.uri}.png`)}
                  alt={game.uri}
                />
                <p>{t(game.name)}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Games;
