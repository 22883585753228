import { Link } from "react-router-dom"

const LinkPage = () => {
    return (
        <>
            <section className="component linkpage">
                <h1>Links</h1>
                <br />
                <h2>Public</h2>
                <Link to="/user/login">Login</Link>
                <Link to="/user/register">Register</Link>
                <br />
                <h2>Private</h2>
                <Link to="/user">Home</Link>
                <Link to="/user/editor">Editors Page</Link>
                <Link to="/user/admin">Admin Page</Link>
            </section>
        </>
    )
}

export default LinkPage